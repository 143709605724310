import { useMutation } from "@apollo/client/react/hooks";
import { UpdateCultivationDense } from "../__generated__/types";
import { CultivationDenseUpdate } from "../pages/21/planning21.gql";

const useUpdateCultivationMutation = () => {
  return useMutation<UpdateCultivationDense>(CultivationDenseUpdate, {
    update(cache, {data}) {
      cache.modify({
        fields: {
          cultivations: (previous) => {
            if (data) {
              return [...previous, data.updateCultivation];
            } else {
              return previous;
            }
          }
        }
      })
    }
  });
};

export default useUpdateCultivationMutation;
