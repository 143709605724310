import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from "@apollo/client/react/hooks";
import { CreateCultivationEvent, CreateCultivationEventVariables, CultivationEventType, Gridder, PlanningData, PlanningData_plantSchemas, Season } from "../../__generated__/types";
import { CreateCultivationEventMutation, GetPlanningDataQuery } from "./planning21.gql";
import { isEmpty } from "lodash";
import { notEmpty } from "../../shared/utils/stream.utils";

interface PlanningContextValue {
  plantSchemas: (PlanningData_plantSchemas)[];
  years: number[],
  setYears: (years: number[]) => void,
  createCultivationEvent: (cultivationId: string, type: CultivationEventType, date: string, note: string | undefined, metadata: any | null, skipped: boolean) => void,
  moveCultivationsToFirstPlot: (rotationAreaId: string) => void,
}

export type Props = {
  children: ReactNode;
  planning?: PlanningContextValue;
};

export interface RotationGroup {
  totalLength: number;
}

export const DEFAULT_YEARS = [2023, 2024, 2025, 2026];

const DEFAULT_CONTEXT_VALUE = {
  years: DEFAULT_YEARS,
  plantSchemas: [],
  setYears: () => {
  },
  createCultivationEvent: () => {
  },
  moveCultivationsToFirstPlot: () => {
  },
};

const PlanningContext = React.createContext<PlanningContextValue>(DEFAULT_CONTEXT_VALUE);

export const plantSchemaForCalculations = (
  cultivation: {
    plantSchema: { plantSchema: { distanceInRow: number, distanceBetweenRows: number, gridder?: Gridder | null  } } | null,
    crop: { plantSchemas: { plantSchema: { distanceInRow: number, distanceBetweenRows: number, gridder?: Gridder | null }, default: boolean }[] | null },
  }) =>
  cultivation.plantSchema ? cultivation.plantSchema : (cultivation.crop.plantSchemas || []).find(p => p.default);

export const SEASONS: { [key: string]: Season } = {
  '2020': Season.S_2020_2021,
  '2021': Season.S_2021_2022,
  '2022': Season.S_2022_2023,
  '2023': Season.S_2023_2024,
  '2024': Season.S_2024_2025,
  '2025': Season.S_2025_2026,
  '2026': Season.S_2026_2027,
};

function PlanningProvider(props: Props) {
  const {children, planning} = props;
  const {data} = useQuery<PlanningData>(GetPlanningDataQuery);
  const [value, setValue] = useState<PlanningContextValue>(planning || DEFAULT_CONTEXT_VALUE);

  const [createCultivationEventMutation] = useMutation<CreateCultivationEvent>(CreateCultivationEventMutation);

  const setYears = useCallback((years: number[]) => {
    setValue(state => ({...state, years}))
  }, [setValue]);

  const createCultivationEvent = (cultivationId: string,
                                  type: CultivationEventType,
                                  date: string, note: string | undefined,
                                  metadata: any | null,
                                  skipped: boolean,
  ) => {
    let variables: CreateCultivationEventVariables = {
      data: {
        date,
        metadata,
        type,
        skipped,
        cultivation: {connect: {id: cultivationId}},
      }
    };
    if (note) {
      variables = {
        data: {
          ...variables.data,
          note: {
            create: {
              text: note,
              type
            }
          }
        }
      };
    }

    createCultivationEventMutation({variables}).then(() => {
    });
  };

  // Initial data fetch
  useEffect(() => {
    setValue(state => {
      let updatedValue = {...state};
      if (data && !isEmpty(data.plantSchemas)) {
        updatedValue = {...updatedValue, plantSchemas: data.plantSchemas.filter(notEmpty)}
      }
      return updatedValue;
    });
  }, [data]);

  return (
    <PlanningContext.Provider value={{
      ...value,
      setYears,
      createCultivationEvent,
    }}>
      {children}
    </PlanningContext.Provider>
  );
}

const usePlanning = () => React.useContext(PlanningContext);

export { PlanningProvider, usePlanning };
