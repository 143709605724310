import { gql } from "@apollo/client";
import { CultivationTasksFragment } from "../../../planning21.gql";

export const ActiveFieldsQuery = gql`
query ActiveFields($farm: ID!) {
  fields(orderBy: orderNumber_ASC, where: {
    active: true
    location: {farm: {id: $farm}}
  }) {
    id
    type
    name
    orderNumber
    rotationAreas(orderBy: number_ASC, where: {active: true}) {
      id
      number
      nextRotationArea {
        id
      }
      plots(orderBy: number_ASC, where: {active: true}) {
        id
        number
      }
      rotationSchema (orderBy: season_ASC) {
        season
        rotationGroup {
          name
        }
      }
    }
  }
}
`;

export const CultivationDetailsQuery = gql`
${CultivationTasksFragment}
query CultivationDetails($id: ID) {
  cultivation(where: {id: $id}) {
    ...CultivationTasks
    id
    plot {
      id
      rotationArea { id field {id bedWidth}}
    }
    crop {
      id
      plantSchemas {
        id
        default
        description
        plantSchema {
          id
          distanceInRow
          distanceBetweenRows
          offset
          gridder
        }
      }
    }
    cropTiming {
      id
      type
    }
    season
    startDate
    harvestEndDate
    pinned
    replicatedFrom {id}
    replicatedTo {id}
    transplant
    interPlanting
    parent {
      id
      plantSchema {
        plantSchema {
          distanceInRow
          distanceBetweenRows
        }
      }
      crop {
        plantSchemas {
          plantSchema {
            distanceInRow
            distanceBetweenRows
          }
          default
        }
      }
    }
    remark
    length
    plantSchema {
      id
      default
      description
      plantSchema {
        id
        distanceInRow
        distanceBetweenRows
        offset
        gridder
      }
    }
    varieties {
      percentage
      id
      variety {
        id
        name
        code
        alternativeCropName
        gramsPerRowMeter
      }
    }
    notes {
      id
      type
      text
    }
  }
}
`;
