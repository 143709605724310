import React from 'react';
import {CultivationsForPlot_cultivations, GroupCultivationsVariables} from "../../../../__generated__/types";
import styled from "styled-components";
import {Button} from "primereact/button";
import useGroupCultivationsMutation from "../../../../hooks/useGroupCultivationsMutation";

interface GroupSelectionButtonProps {
  selection: CultivationsForPlot_cultivations[];
  onSubmit: () => void;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  align-items: center;
  grid-gap: 16px;
  padding: 4px;
`;

const GroupSelectionButton = (props: GroupSelectionButtonProps) => {
  const [groupCultivations] = useGroupCultivationsMutation();

  const onSubmit = () => {
    const variables: GroupCultivationsVariables = {
      ids: props.selection.map(c => ({id: c.id}))
    };
    groupCultivations({variables}).then(() => {
      console.log("grouped");
    });

    props.onSubmit();
  };

  return <Container>
    <div>Groepeer {props.selection.length} teelt{props.selection.length > 1 ? 'en' : ''}</div>

    <Button
      className="input"
      label="Groepeer"
      onClick={() => {
        onSubmit();
      }}
    />
  </Container>;
};

export default GroupSelectionButton;
