import {useMutation} from "@apollo/client/react/hooks";
import {GroupCultivations} from "../__generated__/types";
import {GroupCultivationsUpdate} from "../pages/21/planning21.gql";
import {compact} from "lodash";

const useGroupCultivationsMutation = () => {
  return useMutation<GroupCultivations>(GroupCultivationsUpdate, {
    update(cache, {data}) {
      cache.modify({
        fields: {
          cultivations: (previous) => {
            if (data) {
              return [...previous, ...compact(data.createCultivationGroup.cultivations)];
            } else {
              return previous;
            }
          }
        }
      })
    }
  });
};

export default useGroupCultivationsMutation;
