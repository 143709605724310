import React, { useState } from 'react';
import { CopyCultivationsToPlot, CopyCultivationsToPlotVariables, CultivationCreateWithoutPlotInput, CultivationsForPlot_cultivations, CultivationVarietyCreateInput, MoveCultivationsToPlot, MoveCultivationsToPlotVariables, Season } from "../../../../__generated__/types";
import { useMutation } from "@apollo/client/react/hooks";
import { CopyCultivationsToPlotMutation, MoveCultivationsToPlotMutation } from "../../planning21.gql";
import { compact } from "lodash";
import { calculateCultivationDates } from "./AddCultivation/AddCultivation";
import { startYear } from "../../../../shared/utils/season.util";
import LocationDropDown from "./AddCultivation/components/LocationDropDown/LocationDropDown";
import styled from "styled-components";
import { Button } from "primereact/button";

interface MoveSelectionButtonProps {
  season: Season;
  selection: CultivationsForPlot_cultivations[];
  plotId?: string;
  onSubmit: () => void;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  align-items: center;
  grid-gap: 16px;
  padding: 4px;
`;

const MoveSelectionButton = (props: MoveSelectionButtonProps) => {
  const [moveCultivationsToPlot] = useMutation<MoveCultivationsToPlot>(MoveCultivationsToPlotMutation, {
    update(cache, {data}) {
      cache.modify({
        fields: {
          cultivations: (previous) => {
            if (data) {
              return [...previous, data.updatePlot?.cultivations];
            } else {
              return previous;
            }
          }
        }
      })
    }
  });

  const [targetPlot, setTargetPlot] = useState<string | undefined>(props.plotId);

  function onSubmit() {
    if(targetPlot) {

    const variables: MoveCultivationsToPlotVariables = {
      plotId: targetPlot,
      cultivationReferences: props.selection.map(({id}) => ({id})),
    };

    moveCultivationsToPlot({variables});
    props.onSubmit();
    }
  }

  return <Container>
    <div>
      Verplaats {props.selection.length} teelt{props.selection.length > 1 ? 'en' : ''} naar veld
    </div>

    <div className={'input'}>
      <LocationDropDown
        onChange={(plotId) => {
          setTargetPlot(plotId);
        }}
        season={props.season}
        value={targetPlot}
      />
    </div>

    {targetPlot && <Button
      disabled={props.selection.findIndex(cultivation => cultivation.pinned)>-1}
      className={'input'}
      label={'Verplaatsen'}
      onClick={() => {
        onSubmit();
      }}
    >
    </Button>}
  </Container>
    ;
};

export default MoveSelectionButton;
