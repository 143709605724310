import React, {useEffect, useState} from 'react';
import {
  CopyCultivationsToPlot,
  CopyCultivationsToPlotVariables, CultivationCreateInput,
  CultivationCreateWithoutPlotInput,
  CultivationsForPlot_cultivations,
  CultivationVarietyCreateInput,
  GetPlotInfo,
  Season, UpsertCultivationDense, UpsertCultivationDenseVariables
} from "../../../../__generated__/types";
import {useMutation} from "@apollo/client/react/hooks";
import {CopyCultivationsToPlotMutation, PlotQuery} from "../../planning21.gql";
import {compact, first, orderBy, update} from "lodash";
import {calculateCultivationDates} from "./AddCultivation/AddCultivation";
import {startYear} from "../../../../shared/utils/season.util";
import LocationDropDown from "./AddCultivation/components/LocationDropDown/LocationDropDown";
import styled from "styled-components";
import {Button} from "primereact/button";
import {useQuery} from "@apollo/client";
import UseUpsertCultivationMutation from "../../../../hooks/useUpsertCultivationMutation";
import useUpsertCultivationMutation from "../../../../hooks/useUpsertCultivationMutation";
import useUpdateCultivationMutation from "../../../../hooks/useUpateCultivationMutation";

interface CopySelectionButtonProps {
  season: Season;
  selection: CultivationsForPlot_cultivations[];
  plotId?: string;
  onSubmit: () => void;
}

const Container = styled.div`
  display: grid;
  grid-template-columns: max-content max-content max-content;
  align-items: center;
  grid-gap: 16px;
  padding: 4px;
`;

const CopySelectionButton = (props: CopySelectionButtonProps) => {
  const {data} = useQuery<GetPlotInfo>(PlotQuery, {
    variables: {
      id: props.plotId || ''
    }
  });

  const [upsertCultivation] = useUpsertCultivationMutation();
  const [updateCultivation] = useUpdateCultivationMutation();

  useEffect(() => {
    if (data?.plot) {
      const plotsInNextRotationArea = compact(data.plot.rotationArea?.nextRotationArea?.plots);
      let targetPlot = plotsInNextRotationArea.find(plot => plot.number === data.plot?.number);
      if (targetPlot) {
        targetPlot = first(orderBy(plotsInNextRotationArea, 'number'));
      }
      setTargetPlot(targetPlot?.id)
    }
  }, [data]);

  const [targetPlot, setTargetPlot] = useState<string | undefined>(props.plotId);

  function onSubmit() {
    if (targetPlot) {

      // const variables: CopyCultivationsToPlotVariables = {
      //   plotId: targetPlot,
      //   createMayCultivationsInput: props.selection.map(cultivation => {
      //     const varieties: CultivationVarietyCreateInput[] = compact(cultivation.varieties).map(variety => {
      //       return {
      //         variety: {connect: {id: variety.variety.id}},
      //         percentage: variety.percentage,
      //       };
      //     });
      //
      //     let cultivationCreateWithoutPlotInput: CultivationCreateInput = {
      //       crop: {connect: {id: cultivation.crop.id}},
      //       replicatedFrom: {
      //         connect: {
      //           id: cultivation.id
      //         }
      //       },
      //       cropTiming: {connect: {id: cultivation.cropTiming.id}},
      //       interPlanting: cultivation.interPlanting,
      //       length: cultivation.length,
      //       transplant: cultivation.transplant,
      //       pinned: cultivation.pinned,
      //       plot: {connect: {id: targetPlot}},
      //       varieties: {
      //         create: varieties,
      //       },
      //       ...calculateCultivationDates(startYear(props.season), cultivation),
      //     };
      //
      //     if (cultivation.plantSchema?.id) {
      //       cultivationCreateWithoutPlotInput = {
      //         ...cultivationCreateWithoutPlotInput,
      //         // TEST
      //         plantSchema: {connect: {id: cultivation.plantSchema?.id}},
      //
      //       };
      //     }
      //
      //     const x: UpsertCultivationDenseVariables = {
      //       cultivationId: undefined,
      //       create: cultivationCreateWithoutPlotInput,
      //       update: {},
      //     };
      //     useUpsertCultivation({variables: x})
      //       .then((result) =>{
      //         console.log(result);
      //     });
      //
      //     return cultivationCreateWithoutPlotInput;
      //   })
      // };

      props.selection.forEach(cultivation => {
        const varieties: CultivationVarietyCreateInput[] = compact(cultivation.varieties).map(variety => {
          return {
            variety: {connect: {id: variety.variety.id}},
            percentage: variety.percentage,
          };
        });

        let cultivationCreateWithoutPlotInput: CultivationCreateInput = {
          crop: {connect: {id: cultivation.crop.id}},
          replicatedFrom: {
            connect: {
              id: cultivation.id
            }
          },
          cropTiming: {connect: {id: cultivation.cropTiming.id}},
          interPlanting: cultivation.interPlanting,
          length: cultivation.length,
          transplant: cultivation.transplant,
          pinned: false,
          plot: {connect: {id: targetPlot}},
          varieties: {
            create: varieties,
          },
          ...calculateCultivationDates(startYear(props.season), cultivation),
        };

        if (cultivation.plantSchema?.id) {
          cultivationCreateWithoutPlotInput = {
            ...cultivationCreateWithoutPlotInput,
            // TEST
            plantSchema: {connect: {id: cultivation.plantSchema?.id}},

          };
        }

        const x: UpsertCultivationDenseVariables = {
          cultivationId: '',
          create: cultivationCreateWithoutPlotInput,
          update: {},
        };

        upsertCultivation({variables: x})
          .then((result) =>{
            if(result.data?.upsertCultivation.replicatedFrom) {
              updateCultivation({variables: {
                  cultivationId: result.data?.upsertCultivation.replicatedFrom?.id,
                  data: {
                    replicatedTo: {
                      connect: {id: result.data?.upsertCultivation.id}
                    }
                  },
                }}).then(()=> {})

            }
          });
      })


      // useUpsertCultivation({variables})
      //   .then((result) =>{
      //     console.log(result);
      //   });

      props.onSubmit();
    }
  }

  return <Container>
    <div>
      Dupliceer {props.selection.length} teelt{props.selection.length > 1 ? 'en' : ''} naar
      seizoen {startYear(props.season)} op
      veld
    </div>

    <div className={'input'}>
      <LocationDropDown
        onChange={(plotId) => {
          setTargetPlot(plotId);
        }}
        season={props.season}
        value={targetPlot}
      />
    </div>

    {targetPlot && <Button
      className={'input'}
      label={'Kopiëren'}
      onClick={() => {
        onSubmit();
      }}
    >
    </Button>}
  </Container>
    ;
};

export default CopySelectionButton;
